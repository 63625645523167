import { Box, Button, Modal, Typography } from "@mui/material";
import { useState } from "react";
import {
  BooleanField,
  BooleanInput,
  Datagrid,
  FunctionField,
  List,
  TextField,
  TextInput,
  WrapperField,
} from "react-admin";
import { unixTimestampToString } from "../../../utils/date";
import { ImageField } from "../../Fields/ImageField";
import { ListFilterCleaner } from "../../ListFilterCleaner/ListFilterCleaner";
import { UserPopoverField } from "../../Popover";
import { EventPopoverField } from "../../Popover/EventPopoverField";
import { useImageStyles } from "../User/useImageStyles";
import { FaceRecTagsImage } from "./FaceRecTagsImage";
import { SimilarImagesList } from "./SimilarImagesList";
import { ImageType } from "./types";
import { UserSelectInput } from "../../Inputs";

const imageFilters = [
  <TextInput label="Image id" source="imageId" alwaysOn />,
  <TextInput label="Event id" source="eventId" alwaysOn />,
  <TextInput label="Image name" source="imageName" alwaysOn />,
  <BooleanInput label="Include Deleted?" source="includeDeleted" alwaysOn />,
  <UserSelectInput
    label={"Tagged User"}
    source={"TaggedUserId"}
    alwaysOn
  />,
];

export const ImagesList = () => {
  const [userAnchorEl, setUserAnchorEl] = useState<HTMLButtonElement | null>(
    null
  );
  const [eventAnchorEl, setEventAnchorEl] = useState<HTMLButtonElement | null>(
    null
  );
  const [currentImageId, setCurrentImageId] = useState<number>(0);
  const [currentEventId, setCurrentEventId] = useState<number>(0);
  const [currentImage, setCurrentImage] = useState<ImageType | null>(null);
  const { getImageStyle } = useImageStyles();
  const [similarImagesModalIsOpen, SetSimilarImagesModalIsOpen] =
    useState<boolean>(false);
  const [faceRecTagsModalIsOpen, SetFaceRecTagsModalIsOpen] =
    useState<boolean>(false);

  const handleUserOpen = (
    event: React.MouseEvent<HTMLButtonElement>,
    currentImageId: number
  ) => {
    setUserAnchorEl(event.currentTarget);
    setCurrentImageId(currentImageId);
  };

  const handleUserClose = () => {
    setUserAnchorEl(null);
    setCurrentImageId(0);
  };

  const handleEventOpen = (
    event: React.MouseEvent<HTMLButtonElement>,
    currentImageId: number
  ) => {
    setEventAnchorEl(event.currentTarget);
    setCurrentImageId(currentImageId);
  };

  const handleEventClose = () => {
    setEventAnchorEl(null);
    setCurrentImageId(0);
  };

  const handleOpenSimilarImagesModal = (
    event: React.MouseEvent<HTMLButtonElement>,
    currentImage: ImageType
  ) => {
    SetSimilarImagesModalIsOpen(true);
    setCurrentImage(currentImage);
    setCurrentImageId(currentImage.imageId);
    setCurrentEventId(currentImage.eventId);
  };

  const handleCloseSimilarImagesModal = () => {
    SetSimilarImagesModalIsOpen(false);
    setCurrentImageId(0);
    setCurrentEventId(0);
  };

  const handleOpenFaceRecTagsModal = (
    event: React.MouseEvent<HTMLButtonElement>,
    currentImage: ImageType
  ) => {
    SetFaceRecTagsModalIsOpen(true);
    setCurrentImage(currentImage);
    setCurrentImageId(currentImage.imageId);
    setCurrentEventId(currentImage.eventId);
  };

  const handleCloseFaceRecTagsModal = () => {
    SetFaceRecTagsModalIsOpen(false);
    setCurrentImageId(0);
    setCurrentEventId(0);
  };
  return (
    <>
      <List
        title="Images"
        queryOptions={{
          meta: {
            pagination: true,
            dataExtractFunction: (data: {
              payload: { results: ImageType[] };
            }) => {
              return data.payload.results;
            },
            overridePath: "Image/List",
          },
          refetchOnMount: true,
        }}
        perPage={25}
        filters={imageFilters}
        filterDefaultValues={{ includeDeleted: true }}
      >
        <ListFilterCleaner>
          <Datagrid bulkActionButtons={false}>
            <TextField sortable={false} label="ID" source="imageId" />
            <TextField sortable={false} label="Name" source="imageName" />
            <WrapperField
              sortable={false}
              label="Taken by"
              source={"takenByUser"}
            >
              <UserPopoverField
                handleOpen={handleUserOpen}
                handleClose={handleUserClose}
                anchorEl={userAnchorEl}
                currentResourceId={currentImageId}
                resourceIdKey={"imageId"}
                resource={"takenByUser"}
              />
            </WrapperField>
            <WrapperField sortable={false} label="Event" source={"event"}>
              <EventPopoverField
                handleOpen={handleEventOpen}
                handleClose={handleEventClose}
                anchorEl={eventAnchorEl}
                currentResourceId={currentImageId}
                resourceIdKey={"imageId"}
                resource={"event"}
              />
            </WrapperField>
            <ImageField
              imageSize="Large"
              imageType="originals"
            />
            <FunctionField
              label="Aligned?"
              render={(record: ImageType) => {
                return record.isAligned ?
                  <ImageField imageType="aligned" imageSize="Large"/> :
                  <BooleanField source="isAligned"></BooleanField>
              }}
            />
            <FunctionField
              label="Auto enhanced?"
              render={(record: ImageType) => {
                return record.autoEnhance ?
                  <ImageField imageType="autoenhanced" imageSize="Large"/> :
                  <BooleanField source="autoEnhance"></BooleanField>
              }}
            />
            <FunctionField
              label="Edited?"
              render={(record: ImageType) => {
                return record.hasFilter ?
                  <ImageField label={`${record.imageName}
                    (${getImageStyle(record.style)})
                    ${record.intensity && record.intensity !== 100 ? "[" + record.intensity + "]" : "" }`}
                    imageType="edited" imageSize="Large"/> :
                  <BooleanField source="hasFilter"></BooleanField>
              }}
            />
            <FunctionField
              label="Raw"
              render={(record: ImageType) => {
                return record.rawPath ?
                  <a href={record.rawPath}>Download</a>:
                  <TextField source="rawPath"></TextField>
              }}
            />
            <FunctionField
              label="Uploaded"
              render={(record: ImageType) => {
                return unixTimestampToString(record.createdAt);
              }}
            />
            <BooleanField
              sortable={false}
              label="Deleted?"
              source="isDeleted"
            />
            <FunctionField
              label="Taken at"
              render={(record: ImageType) => {
                return unixTimestampToString(record.takenAt);
              }}
            />
            <FunctionField
              label="Last modified"
              render={(record: ImageType) => {
                return unixTimestampToString(record.lastModified);
              }}
            />
            <FunctionField
              label="Resolution"
              render={(record: ImageType) => {
                return record.width + " x " + record.height;
              }}
            />
            <FunctionField
              label={"Similar images"}
              render={(record: ImageType) => (
                <Button
                  onClick={(e) => handleOpenSimilarImagesModal(e, record)}
                >
                  Similar images
                </Button>
              )}
            />
            <FunctionField
              label={"Face recognition"}
              render={(record: ImageType) => (
                <Button onClick={(e) => handleOpenFaceRecTagsModal(e, record)}>
                  Face recognition
                </Button>
              )}
            />
          </Datagrid>
        </ListFilterCleaner>
      </List>

      <Modal
        open={similarImagesModalIsOpen}
        onClose={handleCloseSimilarImagesModal}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box
          sx={{
            width: "90vw",
            height: "90vh",
            bgcolor: "background.paper",
            border: "2px solid #000",
            p: 4,
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            overflow: "auto",
          }}
        >
          <Typography variant="h5" component="div">
            ImageType {currentImageId} similar images
          </Typography>
          {
            <SimilarImagesList
              eventId={currentEventId}
              imageId={currentImageId}
            />
          }
        </Box>
      </Modal>

      <Modal
        open={faceRecTagsModalIsOpen}
        onClose={handleCloseFaceRecTagsModal}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box
          sx={{
            width: "90vw",
            height: "90vh",
            bgcolor: "background.paper",
            border: "2px solid #000",
            p: 4,
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            overflow: "auto",
          }}
        >
          <Typography variant="h5" component="div">
            Image {currentImageId} face recognition
          </Typography>
          {<FaceRecTagsImage image={currentImage} />}
        </Box>
      </Modal>
    </>
  );
};
