import EditIcon from "@mui/icons-material/Edit";
import { IconButton, Popover, Tooltip, Typography } from "@mui/material";
import { useState } from "react";
import {
  AutocompleteInput,
  AutocompleteInputProps,
  FunctionField,
  SimpleForm,
  useDataProvider,
  useNotify,
} from "react-admin";
import { useMutation, useQueryClient } from "react-query";
import { useImageProcessingStageValues } from "../User/useImageProcessingStageValues";
import { ImageProcessingStage } from "../User/useImageProcessingStages";
import { EventImageProcessing } from "./types";

export type EventProcessingStageActionFieldProps = AutocompleteInputProps & {
  stage: ImageProcessingStage;
};

export const EventProcessingStageActionField = (
  props: EventProcessingStageActionFieldProps
) => {
  const { stage, ...rest } = props;

  const notify = useNotify();
  const queryClient = useQueryClient();
  const dataProvider = useDataProvider();

  const {
    isLoading,
    imageProcessingStageValues,
    getImageProcessingStageValue,
  } = useImageProcessingStageValues();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const getStageValueFromRecord = (
    record: EventImageProcessing,
    stage: ImageProcessingStage
  ) => {
    if (stage.name === "Upload stage") {
      return record.uploadStage;
    }
    if (stage.name === "Align stage") {
      return record.alignStage;
    }
    if (stage.name === "Group stage") {
      return record.groupStage;
    }
    if (stage.name === "Culling stage") {
      return record.cullStage;
    }
    if (stage.name === "Editing stage") {
      return record.editStage;
    }
    if (stage.name === "Face recognition stage") {
      return record.faceRecStage;
    }
    if (stage.name === "Auto-enhance stage") {
      return record.autoEnhanceStage;
    }
    if (stage.name === "Adjustment stage") {
      return record.adjustStage;
    }
    if (stage.name === "Raw convert stage") {
      return record.rawConvertStage;
    }
    return 0;
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const { mutateAsync: updateImageProcessingStage } = useMutation(
    ({ imageId, stageValue }: { imageId: number; stageValue: number }) => {
      return dataProvider.saPost(
        `Image/ImageProcessingStage?ImageId=${imageId}&StageId=${stage.id}&ValueId=${stageValue}`,
        {}
      );
    },
    {
      onSuccess: (data) => {
        notify(`Image processing stage updated successfully`, {
          type: "success",
        });
      },
      onError: () => {
        notify(`Image processing stage update failed`, { type: "error" });
      },
      onSettled: () => {
        queryClient.invalidateQueries("EventProcessing");
      },
    }
  );

  const getImageProcessingStageColor = (stageValue: number) => {
    switch (stageValue) {
      case 0:
        return "gray";
      case 1:
        return "green";
      case 2:
        return "red";
    }
  };

  return (
    <FunctionField
      sortable={false}
      render={(record: EventImageProcessing) => {
        const stageValue = getStageValueFromRecord(record, stage);
        return (
          <>
            <Typography color={getImageProcessingStageColor(stageValue)}>
              {getImageProcessingStageValue(stageValue)}
            </Typography>
            <Tooltip title={`Edit ${stage.name}`}>
              {
                <IconButton onClick={handleOpen}>
                  <EditIcon />
                </IconButton>
              }
            </Tooltip>
            <Popover
              id={String(record.imageId)}
              open={!!anchorEl}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              <SimpleForm toolbar={false} title="Update stage">
                <AutocompleteInput
                  source={stage.name}
                  choices={imageProcessingStageValues}
                  defaultValue={stageValue}
                  onChange={async (e) => {
                    await updateImageProcessingStage({
                      imageId: record.imageId,
                      stageValue: e,
                    });
                    handleClose();
                  }}
                  isLoading={isLoading}
                  isFetching={isLoading}
                  {...rest}
                />
              </SimpleForm>
            </Popover>
          </>
        );
      }}
    />
  );
};
