import {useCallback} from 'react';
import {Admin, Resource, fetchUtils, Layout, CustomRoutes} from 'react-admin';
import {Route} from "react-router-dom";
import {
    AuthenticatedTemplate,
    MsalProvider,
    UnauthenticatedTemplate,
} from "@azure/msal-react";
import {LoginPage} from "./pages";
import {msalInstance, config, TokenProvider, useToken, backendProvider} from "./utils";
import {
    AppBar,
    BackOfficeEquipmentList,
    EnvironmentList,
    EnvironmentManagerEquipmentList,
    FirmwareList,
    BackOfficeEquipmentPopover,
    FirmwareCreate,
    EventList,
    EventTypeList,
    UserList,
    EventTypeCreate,
    EventCreate,
    EventUpdate,
    UserCreate,
    UserUpdate,
    EnvironmentCreate,
    SystemParameterCreate,
    StripePriceCreate,
    EnvironmentUpdate,
    SystemParametersList,
    StripePricesList,
    ImageStylesList,
    ImagesList,
    EventProcessingList,
    LightroomPresetList,
    AccountingTracksList
} from "./components";
import {useAppPicker} from "./hooks";
import CircularProgress from "@mui/material/CircularProgress";
import PersonIcon from '@mui/icons-material/Person';
import VideoSettingsIcon from '@mui/icons-material/VideoSettings';
import SettingsIcon from '@mui/icons-material/Settings';
import PriceChangeIcon from '@mui/icons-material/PriceChange';
import CelebrationIcon from '@mui/icons-material/Celebration';
import WrapTextIcon from '@mui/icons-material/WrapText';
import BugReportIcon from '@mui/icons-material/BugReport';
import DeviceHubIcon from '@mui/icons-material/DeviceHub';
import FilterIcon from '@mui/icons-material/Filter';
import ImageIcon from '@mui/icons-material/Image';
import CloudSyncIcon from '@mui/icons-material/CloudSync';
import LightIcon from '@mui/icons-material/Light';
import {ImageConfigProvider} from "./context/ImageConfigContext";
import { LightroomPresetCreate } from './components/Lists/LightroomPreset/LightroomPresetCreate';

export const AdminAppLayout = (props: any) => <Layout {...props} appBar={AppBar}/>;

const EnvironmentManagerApp = ({dataProvider}: any) => (
    <Admin
        disableTelemetry
        dataProvider={dataProvider}
        layout={AdminAppLayout}
    >
        <Resource
            name="Environment"
            list={EnvironmentList}
            create={EnvironmentCreate}
            edit={EnvironmentUpdate}
            icon={DeviceHubIcon}
            options={{label: "Environments"}}
        />
        <Resource
            name="Firmware"
            list={FirmwareList}
            create={FirmwareCreate}
            icon={BugReportIcon}
            options={{label: "Firmwares"}}
        />
        <Resource
            name="Equipment"
            list={EnvironmentManagerEquipmentList}
            icon={VideoSettingsIcon}
            options={{label: "Equipments"}}
        />
    </Admin>
);

const BackofficeManagerApp = ({dataProvider}: any) => (
    <Admin
        disableTelemetry
        dataProvider={dataProvider}
        layout={AdminAppLayout}
    >
        <Resource
            name="User"
            list={UserList}
            create={UserCreate}
            edit={UserUpdate}
            icon={PersonIcon}
            options={{label: "Users"}}
        />
        <Resource
            name="Equipment"
            list={BackOfficeEquipmentList}
            icon={VideoSettingsIcon}
            options={{label: "Equipments"}}
        />
        <Resource
            name="Event"
            list={EventList}
            create={EventCreate}
            edit={EventUpdate}
            icon={CelebrationIcon}
            options={{label: "Events"}}
        />
        <Resource
            name="EventType"
            list={EventTypeList}
            create={EventTypeCreate}
            icon={WrapTextIcon}
            options={{label: "Event types"}}
        />
        <Resource
            name="SystemParameters"
            list={SystemParametersList}
            create={SystemParameterCreate}
            icon={SettingsIcon}
            options={{label: "System parameters"}}
        />
        <Resource
            name="StripePrices"
            list={StripePricesList}
            create={StripePriceCreate}
            icon={PriceChangeIcon}
            options={{label: "Stripe prices"}}
        />
        <Resource
            name="ImageStyles"
            list={ImageStylesList}
            icon={FilterIcon}
            options={{label: "Image styles"}}
        />
        <Resource
            name="Images"
            list={ImagesList}
            icon={ImageIcon}
            options={{label: "Images"}}
        />
        <Resource
            name="EventProcessing"
            list={EventProcessingList}
            icon={CloudSyncIcon}
            options={{label: "Event processing"}}
        />
        <Resource
            name="LightroomPreset"
            list={LightroomPresetList}
            icon={LightIcon}
            options={{label: "Lightroom presets"}}
            create={LightroomPresetCreate}
        />
        <Resource
            name="AccountingTracks"
            list={AccountingTracksList}
            icon={ImageIcon}
            options={{label: "Accounting"}}
        />
        <CustomRoutes noLayout={true}>
            <Route path="/Equipment/Popover/:serial" element={<BackOfficeEquipmentPopover/>}/>
        </CustomRoutes>
    </Admin>
);

const AdminApp = () => {
    const {currentApp} = useAppPicker();
    const {accessToken} = useToken();

    const httpClient = useCallback((url: string, options: fetchUtils.Options = {}) => {
        options.user = {
            authenticated: accessToken !== null,
            token: `Bearer ${accessToken}`
        };
        return fetchUtils.fetchJson(url, options);
    }, [accessToken]);

    const dataProvider = backendProvider(config.API_URL!, httpClient);

    if (!accessToken || !currentApp) {
        return <CircularProgress/>
    }

    switch (currentApp.type) {
        case "BackofficeManager":
            return <BackofficeManagerApp dataProvider={dataProvider}/>
        case "EnvironmentManager":
            return  <EnvironmentManagerApp dataProvider={dataProvider}/>
        default:
            return <CircularProgress/>;
    }
};

const App = () => {
    return (
        <MsalProvider instance={msalInstance}>
            <UnauthenticatedTemplate>
                <LoginPage/>
            </UnauthenticatedTemplate>
            <AuthenticatedTemplate>
                <TokenProvider>
                    <ImageConfigProvider >
                        <AdminApp/>
                    </ImageConfigProvider>
                </TokenProvider>
            </AuthenticatedTemplate>
        </MsalProvider>
    );
};

export default App;
