import { Modal } from "@mui/material";
import Box from "@mui/material/Box";
import { useRecordContext } from "ra-core";
import { useState } from "react";
import { useImageConfig } from "../../context/ImageConfigContext";

export const ImageField = (props: {
  imageType: string;
  label?: string;
  imageSize?: string;
}) => {
  const record = useRecordContext(props);
  const imageType = props.imageType;
  const imageSize = props.imageSize;
  const { getImageUrl } = useImageConfig();
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const openModal = async () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const imageUrl = getImageUrl(
    record.fileName,
    record.thumbnailFileName,
    imageType,
    imageSize,
    record.rawPath
  );

  const displayName = props.label ?? record.imageName;
  return (
    <>
      <a
        href={imageUrl}
        style={{
          width: "40px",
          height: "40px",
          borderRadius: "50%",
          cursor: "pointer",
          objectFit: "cover",
        }}
        onClick={(event) => {
          openModal();
          event.preventDefault();
        }}
        onMouseOut={closeModal}
      >
        {displayName} ({imageSize})
      </a>
      <Modal
        open={!!modalIsOpen}
        onClose={closeModal}
        aria-labelledby="parent-modal-title"
        style={{
          pointerEvents: "none",
        }}
      >
        <Box
          justifyContent="center"
          alignItems="center"
          display="flex"
          height="100vh"
        >
          <img
            src={imageUrl} // Display the original image here
            alt={imageUrl}
            style={{
              height: "90vh",
              objectFit: "contain",
              border: "3px solid #ccc",
            }}
          />
        </Box>
      </Modal>
    </>
  );
};
