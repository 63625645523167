import React, {useCallback, useEffect, useState} from 'react';
import {useGetList, AutocompleteInput, AutocompleteInputProps} from "react-admin";

export const EventTypeSelectInput = (props: AutocompleteInputProps) => {
    const {label, source, ...rest} = props;

    const [data, setData] = useState<any[] | undefined>([]);

    const dataExtractFunction = useCallback((data: { payload: any }) => {
        return data.payload;
    }, []);

    const {data: originalData, isLoading, error} = useGetList(
        'EventType',
        {
            meta: {
                pagination: false,
                alternativeId: 'eventTypeId',
                dataExtractFunction: dataExtractFunction
            },
        },
        {
            refetchOnMount: false,
        }
    );

    useEffect(() => {
        if (!data?.length) {
            setData(originalData);
        }
    }, [originalData, data?.length]);


    if (error) {
        return null;
    }

    return (
        <AutocompleteInput
            label={label}
            source={source}
            choices={data?.map((eventType: any) => ({id: eventType.eventTypeId, name: eventType.name}))}
            isLoading={isLoading}
            isFetching={isLoading}
            {...rest}
        />
    );
}
