import { useEffect, useMemo, useRef } from "react";
import { ImageType } from "./types";
import { useImageConfig } from "../../../context/ImageConfigContext";

interface FaceRecTagsImageProps {
    image: ImageType | null;
}

export const FaceRecTagsImage = (props: FaceRecTagsImageProps) => {

    const image = props.image;
    const imageId = image?.imageId;

    const { getImageUrl } = useImageConfig();
    const faceRecCanvas = useRef<HTMLCanvasElement>(null);

    const generateRandomColor = () => `hsla(${Math.random() * 360}, 100%, 50%, 1)`;

    const randomColors: string[] = useMemo(
        () => {
            let result : string[] = [];
            for (let i = 0; i < 256; ++i)    {
                result.push(generateRandomColor());
            }
            return result;
        }, []
    );

    useEffect(() => {
        const canvas: HTMLCanvasElement | null = faceRecCanvas.current;
        const canvasContext = canvas?.getContext('2d')!;
        if (canvas !== null && canvasContext !== null) {

            canvasContext.clearRect(0, 0, canvas.width, canvas.height);
            canvasContext.strokeText("Loading....", 0, 0);

            const imageUrl = getImageUrl(image?.fileName, image?.thumbnailFileName, "edited", "Large");
            const img = new Image();
            img.src = imageUrl;
            img.onload = () => {
                const width = img.width;
                const height = img.height;
                canvas.width = width;
                canvas.height = height;
                canvasContext.drawImage(img, 0, 0);
                canvasContext.lineWidth = 2;
                canvasContext.font = "16pt Courier";
                canvasContext.fillStyle = "white";
                let faceIndex = 0;
                image?.faceRecTags.forEach(faceRecTag => {
                    canvasContext.strokeStyle = randomColors[faceIndex++];
                    const rect = faceRecTag.tagData.rectangle;
                    canvasContext.strokeRect(rect[0] * width, rect[1] * height,
                        (rect[2]-rect[0]) * width, (rect[3]-rect[1]) * height);
                    canvasContext.strokeText("User " + String(faceRecTag.userId),
                        rect[0] * width, rect[1] * height - 10);

                })
            };
        }
    }, [image, imageId, getImageUrl, faceRecCanvas, randomColors])

    return <>
            <canvas ref={faceRecCanvas} {...props}/>
        </>

}

export default FaceRecTagsImage;