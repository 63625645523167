import {dateToTimestamp, timestampToDateString} from "../../../utils";
import {BooleanInput, DateTimeInput, required, SimpleForm, TextInput} from "react-admin";
import {EventTypeSelectInput, UserSelectInput} from "../../Inputs";
import {EventCreateInput, EventUpdateInput} from "../../../types";
import { EventProcessingStatusSelectInput } from "../../Inputs/EventProcessingStatusSelectInput";

interface UpsertEventFormProps {
    onSubmit: (input: EventCreateInput | EventUpdateInput) => void;
    isUpdate: boolean;
}

export const UpsertEventForm = (props: UpsertEventFormProps) => {
    const {onSubmit, isUpdate} = props;
    return (
        <SimpleForm
            onSubmit={async (formValues) => {
                await onSubmit({
                    ...formValues,
                    start: formValues.start,
                    end: formValues.end,
                } as EventCreateInput);
                return
            }}
            sx={{width: '50%'}}
        >
            <TextInput source="name" validate={[required()]} fullWidth/>
            <EventTypeSelectInput source="eventTypeId" label={"Event Type"} validate={[required()]} fullWidth/>
            <DateTimeInput
                source="start"
                validate={[required()]}
                format={timestampToDateString}
                parse={dateToTimestamp}
                fullWidth
            />
            <DateTimeInput
                source="end"
                validate={[required()]}
                format={timestampToDateString}
                parse={dateToTimestamp}
                fullWidth
            />
            <TextInput source="description" fullWidth/>
            <TextInput source="comments" fullWidth/>
            <TextInput source="location" fullWidth/>
            <EventProcessingStatusSelectInput source="processingStatus" label={"Processing Status"} fullWidth/>
            <BooleanInput source="isGuestsAutoApproval" label="Guests are auto approved" fullWidth/>
            {
                !isUpdate && (
                    <UserSelectInput onlyPhotographer source="photographerId" label={"Photographer"} validate={[required()]} fullWidth/>
                )
            }
            {
                isUpdate && (
                    <BooleanInput source="faceRecEnabled" label="Face recognition enabled" fullWidth/>
                )
            }
            {
                isUpdate && (
                    <BooleanInput source="isDemoSource" label="Is demo source" fullWidth/>
                )
            }
        </SimpleForm>
    )
}
