import React, {useState, useEffect} from "react";
import Button from "@mui/material/Button";
import Popover from "@mui/material/Popover";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    Tooltip
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import EditIcon from "@mui/icons-material/Edit";
import {useDataProvider} from "ra-core";
import {useMutation, useQueryClient} from 'react-query';
import {useNotify} from "react-admin";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";

export type EditSystemParameterActionProps = {
    record: any;
}

export const EditSystemParameterAction = ({record}: EditSystemParameterActionProps) => {
    const notify = useNotify();

    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [currentSystemParameterValue, setCurrentSystemParameterName] = useState<string>(record.paramValue);
    const [dialogIsOpen, setDialogIsOpen] = useState<boolean>(false);

    useEffect(() => {
        setCurrentSystemParameterName(record.paramValue);
    }, [record]);

    const queryClient = useQueryClient();
    const dataProvider = useDataProvider();

    const { mutateAsync: updateSystemParameter, isLoading: isLoadingUpdateSystemParameter } = useMutation(() => {
        return dataProvider.saPost(`SystemParameters?ParamName=${record.paramName}&ParamValue=${currentSystemParameterValue}`, {});
    }, {
        onSuccess: ( response: { data: { code: number, message: string } } ) => {
            if (response.data.code === 0) {
                notify(`System parameter updated ${record.paramName} = ${currentSystemParameterValue}`, { type: 'success' });
            } else {
                notify(`System parameter update failed ${response.data.message}`, { type: 'error' });
            }
        },
        onError: () => {
            notify("System parameter update failed", { type: 'error' });
        },
        onSettled: () => {
            queryClient.invalidateQueries('SystemParameters');
        }
    });

    const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const onSave = async () => {
        setDialogIsOpen(true);
        return;
    }

    const handleDialogOk = async () => {
        setDialogIsOpen(false);
        await updateSystemParameter();
        handleClose();
    }

    const handleDialogClose = () => {
        setDialogIsOpen(false);
        handleClose();
    }

    if (isLoadingUpdateSystemParameter) {
        return <CircularProgress />;
    }

    return (
        <>
            <Tooltip title={`Edit system parameter`}>
                <IconButton onClick={handleOpen}>
                    <EditIcon/>
                </IconButton>
            </Tooltip>
            <Popover
                id={record.paramName}
                open={!!anchorEl}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <Box sx={{
                    width: 300,
                    height: 150,
                    paddingY: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'space-between'
                }}>
                    <Typography sx={{p: 2}}>Edit system parameter</Typography>
                    <TextField
                        value={currentSystemParameterValue}
                        label="System parameter"
                        variant="outlined"
                        onChange={(e) => setCurrentSystemParameterName(e.target.value)}
                    />
                    <Button
                        variant="outlined"
                        startIcon={<CheckIcon/>}
                        onClick={onSave}>
                        Save
                    </Button>
                </Box>
            </Popover>
            <Dialog
                open={dialogIsOpen}
                onClose={handleDialogClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Edit System Parameter?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {`Change system parameter ${record.paramName} = ${currentSystemParameterValue} ?`}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose}>Cancel</Button>
                    <Button onClick={handleDialogOk} autoFocus>
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

