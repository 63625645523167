import React, {useState} from "react";

import FormatPaint from '@mui/icons-material/FormatPaint';
import {Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Tooltip} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import {useMutation, useQueryClient} from "react-query";
import {BooleanInput, NumberInput, SimpleForm, useNotify} from "react-admin";
import {useDataProvider} from "ra-core";
import { ImageStyleSelectInput } from "../../Inputs/ImageStyleSelectInput";
import { EventType } from "./types";

export type UpdateStyleActionProps = {
    record: EventType;
}

export const UpdateStyleAction = ({record}: UpdateStyleActionProps) => {
    const notify = useNotify();
    const queryClient = useQueryClient();
    const dataProvider = useDataProvider();

    const [dialogIsOpen, setDialogIsOpen] = useState<boolean>(false);

    const {mutateAsync: UpdateStyleEvent, isLoading: isLoadingDeleteEvent} = useMutation(() => {
        const eventId = record.eventId;
        if (!eventId) {
            return;
        }
        const eventEditParams = {
            Style: selectedStyle,
            AutoEnhance: selectedAutoEnhance,
            Intensity: selectedIntensity
        };
        return dataProvider.saPost(`Event/${eventId}/Styles/Edit`, eventEditParams);
    }, {
        onSuccess: data => {
            notify("Event " + record.name + " resend to editing successfully", {type: 'success'});
        },
        onError: () => {
            notify("Event " + record.name + " resend to editing failed", {type: 'error'});
        },
        onSettled: () => {
            queryClient.invalidateQueries('Event');
        }
    });

    const handleClick = () => {
        setDialogIsOpen(true);
        return;
    }

    const handleDialogOk = async () => {
        setDialogIsOpen(false);
        await UpdateStyleEvent();
        return;
    }

    const handleDialogClose = () => {
        setDialogIsOpen(false);
        return;
    }

    const [selectedStyle, setSelectedStyle] = useState<number>(0);
    const [selectedAutoEnhance, setSelectedAutoEnhance] = useState<boolean>(true);
    const [selectedIntensity, setSelectedIntensity] = useState<number>(100);

    return (
        <>
            <Tooltip title="Resend event to editing">
                {
                    isLoadingDeleteEvent ? <CircularProgress/> : (
                        <IconButton onClick={handleClick}>
                            <FormatPaint/>
                        </IconButton>
                    )
                }
            </Tooltip>
            <Dialog
                open={dialogIsOpen}
                onClose={handleDialogClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Resend event " + record.name + " to editing?"}
                </DialogTitle>
                <DialogContent>
                    <SimpleForm toolbar={false}>
                        <ImageStyleSelectInput defaultValue={0} onChange={setSelectedStyle} source="style" label="Image style"/>
                        <BooleanInput defaultValue={true} onChange={(e) => setSelectedAutoEnhance(e.target.checked)} source="autoEnhance" label="Auto enhance"/>
                        <NumberInput defaultValue={100} onChange={(e) => setSelectedIntensity(parseInt(e.target.value))} source="intensity" label="Intensity"/>
                    </SimpleForm>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose}>Cancel</Button>
                    <Button onClick={handleDialogOk} autoFocus>
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
