import {BooleanField, BooleanInput, Datagrid, List, Pagination, TextField, TextInput, WrapperField} from "react-admin";
import {ListFilterCleaner} from "../../ListFilterCleaner/ListFilterCleaner";
import { ImageStyleActionsField } from "./ImageStyleActionsField";
import { ImageStyle } from "./types";
import { LightroomPresetPopoverField } from "../../Popover/LightroomPresetPopoverField";
import { useState } from "react";

const imageStyleFilters = [
    <TextInput
        label="Image style name"
        source="name"
        resettable
        alwaysOn
    />,
    <BooleanInput
        label="Only available"
        source="onlyAvailable"
        alwaysOn
    />,
    <TextInput
        label="Lightroom preset ID"
        source="lightroomPresetId"
        resettable
    />,
    <BooleanInput
        label="Is Lightroom Preset?"
        source="isLightroomPreset"
    />,
];

export const ImageStylesList = () => {

    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [currentImageStyleId, setCurrentImageStyleId] = useState<number | null>(null);

    const handleOpen = (event: React.MouseEvent<HTMLButtonElement>, currentLightroomPresetId: number) => {
        setAnchorEl(event.currentTarget);
        setCurrentImageStyleId(currentLightroomPresetId);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setCurrentImageStyleId(null);
    };

    return (
        <List
            title="Image styles"
            queryOptions={{
                meta: {
                    pagination: true,
                    dataExtractFunction: (data: { payload: { results: ImageStyle[] } }) => {
                        return data.payload.results;
                    },
                    overridePath: 'ImageStyles'
                },
                refetchOnMount: true,
            }}
            perPage={100}
            pagination={<Pagination rowsPerPageOptions={[25, 50, 100, 250, 500, 1000]} />}
            filters={imageStyleFilters}
        >
            <ListFilterCleaner>
                <Datagrid bulkActionButtons={false}>
                    <TextField sortable={false} label="id" source="id"/>
                    <TextField sortable={false} label="Name" source="name"/>
                    <BooleanField sortable={false} label="Available ?" source="available"/>
                    <TextField sortable={false} label="Category" source="category"/>
                    <WrapperField source={'lightroomPreset'}>
                        <LightroomPresetPopoverField
                            handleOpen={handleOpen}
                            handleClose={handleClose}
                            anchorEl={anchorEl}
                            currentResourceId={currentImageStyleId}
                            resourceIdKey={'lightroomPreset'}
                            resource={'lightroomPreset'}
                        />
                    </WrapperField>
                    <TextField sortable={false} label="Comment" source="comment"/>
                    <TextField sortable={false} label="Order Index" source="orderIndex"/>
                    <ImageStyleActionsField/>
                </Datagrid>
            </ListFilterCleaner>
        </List>
    )
}
