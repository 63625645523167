import {
    BooleanField,
    Datagrid,
    FunctionField,
    List,
    TextField,
} from "react-admin";
import { ImageField } from "../../Fields/ImageField";
import { ImageType } from "./types";
import { unixTimestampToString } from "../../../utils/date";
import { useImageStyles } from "../User/useImageStyles";

interface SimilarImagesListProps {
    imageId: number;
    eventId: number;
}

export const SimilarImagesList = (props: SimilarImagesListProps) => {
    const {
        imageId,
        eventId,
    } = props;

    const { getImageStyle } = useImageStyles();

    return (
        <List
            title={`Image ${imageId} similar images`}
            queryOptions={{
                meta: {
                    overridePath: "Image/Similar",
                    alternativeId: 'imageId',
                    pagination: false,
                    dataExtractFunction: (data: { payload: ImageType[] }) => {
                        return data.payload;
                    }
                },
                refetchOnMount: false,
            }}
            filter={{ "EventId": eventId, "ImageId": imageId}}
        >
            <Datagrid bulkActionButtons={false}>
                <TextField label="ID" source="imageId"/>
                <TextField label="Name" source="imageName"/>
                <BooleanField sortable={false} label="Deleted?" source="isDeleted"/>
                <BooleanField sortable={false} label="Edited?" source="hasFilter"/>
                <FunctionField label="Taken at" render={(record: ImageType) => {
                    return unixTimestampToString(record.takenAt);
                }}/>
                {<ImageField label="Edited" imageSize="Large" imageType="edited"/>}
                <FunctionField label="Style" render={(record: ImageType) => {
                    return getImageStyle(record.style);
                }}/>
            </Datagrid>
        </List>
    );
}
